import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    validateEmail,
    validatePhoneNumber,
    getKey
} from '../utils/helpers';
import {
    updateLead
} from '../utils/Actions';

const Virtualassistant = ({ formRef }) => {

    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [prompt, setPrompt] = useState('Please feel free to ask me anything about curbing. How can I help you today?');
    const [response, setResponse] = useState('');
    const [step, setStep] = useState(2);
    const paragraphRef = useRef(null);

    // this hook will run every time the next question is asked, if the phone or email are legit
    useEffect(() => {

        const abc = async () => {

            if ((step > 1) && ((validateEmail(email) || validatePhoneNumber(phone))))
                dispatch(updateLead({ ...state.lead, phone: phone, email: email, comment: prompt }));

            if (step > 2) {
                try {
                    // setPrompt(prompt + `\n\n` + response + `/n/n Let me check that for you...`);
                    setPrompt(<>
                        {prompt}
                        <br /><br />
                        {response && (<>{response}<br /><br /></>)}
                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                          Let me check that for you...
                        </span>
                      </>);
                    const aiResponse = await fetch('/api/askai', { // request ai model response
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': getKey() // this is for protection of the server route
                        },
                        body: JSON.stringify({response: response}),
                    });
                    const data = await aiResponse.json();
                    setPrompt(prompt + `\n\n` + (response ? response + `\n\n` : '') + (data.response ? data.response + `\n\n` : '') + `\n\n Anything else I can help you with?`);
                    setResponse('');
                } catch (err) {
                    console.error(err);
                };
            };

            if ((step === 3) && ((validateEmail(email) || validatePhoneNumber(phone))))
                try {
                    await fetch('/api/assistantlead', { // email the lead data from virtual assistant
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': getKey() // this is for protection of the server route
                        },
                        body: JSON.stringify(state.lead),
                    });

                } catch (err) {
                    console.error(err);
                };

        };
        abc();
    }, [step]);

    useEffect(() => {
        // Scroll to the bottom of the paragraph whenever the content changes
        if (paragraphRef.current) {
            paragraphRef.current.scrollTop = paragraphRef.current.scrollHeight;
        }
    }, [prompt]); // Depend on prompt to update scroll position whenever it changes

    useEffect(() => {
        const abc = async () => {

            if ((step > 2) && !isOpen) 
                try {
                    await fetch('/api/assistantlead', { // Email history of the conversation
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': getKey() // this is for protection of the server route
                        },
                        body: JSON.stringify({phone:'diagnostics to improve model conversation', email:'-', comment: prompt}),
                    });

                } catch (err) {
                    console.error(err);
                };

        };
        abc();
    }, [isOpen]); // hook runs when the chat window is open and closed


    const toggleChatWindow = () => {
        setIsOpen(!isOpen);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Reset form fields
        // setPhone('');
        // setEmail('');
        // Close the chat window after submission
        // setIsOpen(false);
        setStep(2);
    };

    const handleResponse = (e) => {
        e.preventDefault();

        setStep(step + 1);
    };

    return (
        <>
            <div
                className="fixed bottom-5 right-5 bg-green-700 text-white p-4 rounded-full cursor-pointer text-xl"
                onClick={toggleChatWindow}
            >
                💬 Ask a Question
            </div>

            {isOpen && (
                <div className="fixed bottom-20 right-5 w-80 bg-white shadow-lg rounded-lg z-50">
                    <div className="bg-green-700 text-white px-4 py-2 flex justify-between items-center rounded-t-lg">
                        <span>Virtual Assistant</span>
                        <span
                            className="cursor-pointer text-2xl"
                            onClick={toggleChatWindow}
                        >
                            &times;
                        </span>
                    </div>
                    <div className="p-4">
                        {step === 1 ? (
                            <div>
                                <p className="mb-4">Hello, I am your virtual assistant. Please let me know your phone or e-mail below so that we can follow up your question.</p>
                                <form>
                                    <label htmlFor="phone" className="block text-sm mb-2">
                                        Phone:
                                    </label>
                                    <input
                                        type="text"
                                        id="phone"
                                        className="w-full p-2 mb-4 border border-gray-300 rounded"
                                        value={phone}
                                        onChange={(e) => { e.preventDefault(); setPhone(e.target.value) }}
                                    />
                                    <label htmlFor="email" className="block text-sm mb-2">
                                        Email:
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        className="w-full p-2 mb-4 border border-gray-300 rounded"
                                        value={email}
                                        onChange={(e) => { e.preventDefault(); setEmail(e.target.value) }}
                                    />
                                    <button
                                        type="submit"
                                        className="w-full bg-green-700 text-white py-2 rounded hover:bg-green-800"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </form>
                            </div>
                        ) : (
                            <div>
                                <p
                                    ref={paragraphRef}
                                    className="mb-4 max-h-40 overflow-y-auto whitespace-pre-wrap">
                                    {prompt}
                                </p>
                                <input
                                    type="text"
                                    placeholder="Type your question here..."
                                    className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:border-green-300"
                                    value={response}
                                    onChange={(e) => { e.preventDefault(); setResponse(e.target.value) }}
                                    onKeyDown={(e) => { 
                                        if (e.key === 'Enter') handleResponse(e);    
                                    }}
                                />
                                <button
                                    className="w-full bg-green-700 text-white py-2 rounded hover:bg-green-800"
                                    onClick={handleResponse}
                                >
                                    Ask
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );


}
export default Virtualassistant;