import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const Technology = () => {

    return (
        <div className="rounded-lg bg-gray-50">
            <p className="mt-4 font-bold text-center text-3xl sm:text-2xl">
                Why Choose Curb-Ease?
            </p>
    
            <div className="px-4 py-5 sm:p-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
    
                    {/* First Item */}
                    <div className="flex text-sm leading-6 text-gray-600 items-start">
                        <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                        <div className="ml-2">
                            <p className="font-bold">On-Site Extrusion</p>
                            <p className="mt-1">
                                Concrete is extruded continuously on-site, following the natural grade of your landscape (curbs can go up and down).
                            </p>
                        </div>
                    </div>
    
                    {/* Second Item */}
                    <div className="flex text-sm leading-6 text-gray-600 items-start">
                        <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                        <div className="ml-2">
                            <p className="font-bold">Root Barrier</p>
                            <p className="mt-1">
                                Unlike block-based solutions, our concrete curbing is a single piece, acting as a root barrier and preventing grass from growing through.
                            </p>
                        </div>
                    </div>
    
                    {/* Third Item */}
                    <div className="flex text-sm leading-6 text-gray-600 items-start">
                        <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                        <div className="ml-2">
                            <p className="font-bold">Durable Alternative</p>
                            <p className="mt-1">
                                Replaces black plastic edging, maintaining the
                                form of flowerbeds and effectively holding
                                mulch and dirt in place.
                            </p>
                        </div>
                    </div>
    
                    {/* Fourth Item */}
                    <div className="flex text-sm leading-6 text-gray-600 items-start">
                        <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                        <div className="ml-2">
                            <p className="font-bold">Variety of Designs</p>
                            <p className="mt-1">
                                Available in numerous stamped concrete
                                patterns, curb profiles, and colour options to
                                suit your aesthetic preferences.
                            </p>
                        </div>
                    </div>
    
                    {/* Fifth Item */}
                    <div className="flex text-sm leading-6 text-gray-600 items-start">
                        <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                        <div className="ml-2">
                            <p className="font-bold">Integral Colours</p>
                            <p className="mt-1">
                                Pigments are added to the concrete mix,
                                ensuring colours do not fade or change
                                over time.
                            </p>
                        </div>
                    </div>
                    {/* Sixth Item */}
                <div className="flex text-sm leading-6 text-gray-600 items-start">
                    <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                    <div className="ml-2">
                        <p className="font-bold">Low Maintenance</p>
                        <p className="mt-1">
                            Similar to road curbs, our decorative
                            concrete requires minimal upkeep and
                            retains its appearance for years.
                        </p>
                    </div>
                </div>

                {/* Seventh Item */}
                <div className="flex text-sm leading-6 text-gray-600 items-start">
                    <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                    <div className="ml-2">
                        <p className="font-bold">Minimal Disruption</p>
                        <p className="mt-1">
                            Our equipment is lawn mower-sized,
                            allowing easy backyard access without
                            creating a construction mess.
                        </p>
                    </div>
                </div>

                {/* Eighth Item */}
                <div className="flex text-sm leading-6 text-gray-600 items-start">
                    <CheckCircleIcon className="h-10 w-10 flex-none text-green-700" aria-hidden="true" />
                    <div className="ml-2">
                        <p className="font-bold">Quality Assurance</p>
                        <p className="mt-1">
                            We provide a 5-year warranty, backed by a
                            franchise system that guarantees high-
                            quality materials and standards.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
);

    
}
export default Technology;