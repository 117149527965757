import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateAddress } from '../utils/Actions';
import { getKey, getGeo } from '../utils/helpers';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
};

function transform(data) {
    if ((!data.Items) || Boolean(data.Items[0].Error)) return [];
    return data.Items.map((item, index) => ({
        id: index,
        name: item.Text + ' ' + item.Description,
        street: item.Text,
        cityProvincePostalCode: item.Description
    }));
};

const Address = () => {
    const [query, setQuery] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('')
    const [addresses, setAddresses] = useState('');

    const dispatch = useDispatch();

    useEffect(() => { // that hook fetches the list of addresses from API based on the query
        const fetchAddress = async () => {
            try {
                const response = await fetch(
                    'api/address',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': getKey() // this is for protection of the server route
                        },
                        body: JSON.stringify({ value: query }),
                    }
                );

                const data = await response.json();
                setAddresses(transform(data));
            } catch (error) {
                console.error('Error fetching address:', error);
            }
        };

        fetchAddress();
    }, [query]);

    useEffect(() => { // that hook updates the state once the address is selected

        if (selectedAddress) 
            dispatch(updateAddress(
                selectedAddress.street, // street name
                selectedAddress.cityProvincePostalCode.split(',')[0].trim(), // city
                selectedAddress.cityProvincePostalCode.split(',').pop().trim(), // postal code
                selectedAddress.cityProvincePostalCode.split(',')[1].trim() // province
            ));
            
        const fetchGeo = async () => {
            console.log(await getGeo(selectedAddress.street + ' ' + selectedAddress.cityProvincePostalCode.split(',')[0].trim() + ' ' + selectedAddress.cityProvincePostalCode.split(',')[1].trim() + ' Canada'));
        };
        // fetchGeo(); - reserved for the future use to automatically decide which estimator calendar to use

    }, [selectedAddress]);


    return (
        <Combobox as="div" value={selectedAddress} onChange={setSelectedAddress}>
            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">Find address for estimate</Combobox.Label>
            <div className="relative mt-2">
                <Combobox.Input
                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:text-sm sm:leading-6"
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(address) => address?.name}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>

                {addresses.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {addresses.map((address) => (
                            <Combobox.Option
                                key={address.id}
                                value={address}
                                className={({ active }) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-8 pr-4',
                                        active ? 'bg-green-700 text-white' : 'text-gray-900'
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span className={classNames('block truncate', selected && 'font-semibold')}>{address.name}</span>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                                    active ? 'text-white' : 'text-green-700'
                                                )}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
};

export default Address;
