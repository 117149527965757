

const initialState = {

    lead:
    {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        country: 'Canada',
        streetAddress: '',
        city: '',
        region: '',
        postalCode: '',
        audience:
        {
            curbing: false,
            resurfacing: false,
            grass: false,
            ottawa: false,
            golf: false,
            municipality: false
        },
        comment: '',
    },

    dummyLead:
    {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        country: 'Canada',
        streetAddress: '',
        city: '',
        region: '',
        postalCode: '',
        audience:
        {
            curbing: false,
            resurfacing: false,
            grass: false,
            ottawa: false,
            golf: false,
            municipality: false
        },
        comment: '',
    },

    message: { header: null, result: null, style: false }, // use style: false for error, true for success messages

    status: null, // displays dynamic status messages as the form is filled out

    calendly: false, // indicates if the calendly widget is visible

    calendlyUrl: null // the url for the calendly widget, unique for each estimator calendar

};

export function reducer(state = initialState, action) {
    switch (action.type) {
        case 'updateMessage':
            return {
                ...state,
                message: action.payload
            };
        case 'updateStatus':
            return {
                ...state,
                status: action.payload
            };
        case 'updateLead':
            return {
                ...state,
                lead: action.payload
            };
        case 'updateCalendly':
            return {
                ...state,
                calendly: action.payload
            };
            case 'updateCalendlyUrl':
                return {
                    ...state,
                    calendlyUrl: action.payload
                };
        case 'updateAudience':
            return {
                ...state,
                lead: {
                    ...state.lead,
                    audience: action.payload
                }
            };
            case 'updateAddress':
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        streetAddress: action.payload.streetAddress,
                        city: action.payload.city,
                        postalCode: action.payload.postalCode,
                        region: action.payload.region
                    }
                };
        default:
            return state;
    }
};