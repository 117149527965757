import React from 'react';
import { ArrowTrendingUpIcon } from '@heroicons/react/24/solid';

const Videotech = ({ formRef }) => {

    const scrolltoForm = (e) => {
        // Preventing the default behavior of the form submit (which is to refresh the page)
        e.preventDefault();
        if (formRef && formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className="bg-green-50 p-4 mb-8">
        <div className="flex flex-col md:flex-row items-center md:items-center justify-between">
            {/* Left Column: Text and Button */}
            <div className="md:w-1/2 flex flex-col justify-start items-start text-left md:text-left mb-4 md:mb-0">
                <p className="mt-6 mx-8 text-gray-600 text-2xl sm:text-xl">
                    <span className="font-bold">Curb-Ease®</span> installs a
                    continuous extrusion of
                    stamped concrete edging
                    around flowerbeds, walkways,
                    driveways and patios.
                </p>
                <button
                    onClick={scrolltoForm}
                    className="min-w-[13rem] w-52 rounded-md bg-green-700 px-4 py-2 text-lg font-semibold text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2 mt-4 mx-8"
                >
                    Get a Free Estimate
                </button>
            </div>
    
            {/* Right Column: Video */}
            <div className="md:w-1/2 flex flex-col justify-center">
                <div className="flex items-center justify-center mt-4 md:mt-0">
                    <ArrowTrendingUpIcon
                        className="h-12 w-12 text-green-700"
                        style={{ transform: 'scaleX(-1) scaleY(-1)' }}
                    />
                    <p className="mt-1 text-gray-600 text-center mr-2">
                        Watch a short video to see how it works!
                    </p>
                </div>
    
                <div className="video-container mt-4" style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                    <iframe
                        src={`https://youtube.com/embed/YCLC3Z4d6zk`}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: '12px',
                        }}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded YouTube Video"
                    />
                </div>
            </div>
        </div>
    </div>
    


    );


}
export default Videotech;