import React from 'react';
import { ClipboardDocumentCheckIcon, BeakerIcon, BugAntIcon } from '@heroicons/react/20/solid';
//import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";

const features = [
    {
        name: 'Curbing profiles',
        description:
            'Continuously extruded, our curbs follow the grade. The shapes are precisely what they need to be, with perfect edging dividing landscape design and synthetic grass.',
        href: 'https://www.curb-ease.com/profiles',
        icon: BeakerIcon,
    },
    {
        name: 'Synthetic grass',
        description:
            'Designed to perform in the ever-changing Canadian climate, the use of breakthrough materials and processes in manufacturing ensures that they will look and feel just as realistic and beautiful for years to come.',
        href: '/grass',
        icon: BugAntIcon,
    },
    {
        name: 'Certified installers',
        description:
            'Curb-Ease® installation trailers are fully equipped and prepared to install hundreds of feet of curbing per day, ensuring prompt completion of large projects and minimizing inconvenience to our customers. We highly value and respect professional relationships and continuously thrive on industry recommendations.',
        href: '/',
        icon: ClipboardDocumentCheckIcon,
    },
];

const files = [
    {
        title: '5.jpeg',
        size: '11 MB',
        source:
            `${require('../assets/gallery/5.jpeg')}`,
    },
    {
        title: '7.jpeg',
        size: '9 MB',
        source:
            `${require('../assets/gallery/7.jpeg')}`,
    },
    {
        title: '8.jpeg',
        size: '11.6 MB',
        source:
            `${require('../assets/gallery/8.jpeg')}`,
    },
    {
        title: '9.jpeg',
        size: '11.5 MB',
        source:
            `${require('../assets/gallery/9.jpeg')}`,
    },
    {
        title: '10.jpeg',
        size: '15.1 MB',
        source:
            `${require('../assets/gallery/10.jpeg')}`,
    },
]

function Golf() {

    return (
        <>
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:text-left">
                        <h2 className="text-3xl font-semibold leading-7 text-green-700">Golf Club and Golf Course Solutions</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Everything you need to increase your business revenue
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Curb-Ease® provides a variety of concrete edging and synthethic grass solutions for golf.
                            We partner with the leading architects to construct the best putting greens and mini golf courses.
                        </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2 px-16 lg:px-32">
                            {features.map((feature) => (
                                <div key={feature.name} className="flex flex-col">
                                    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                                        <feature.icon className="h-5 w-5 flex-none text-green-700" aria-hidden="true" />
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                                        <p className="flex-auto">{feature.description}</p>
                                        <p className="mt-6">
                                            <a href={feature.href} className="text-sm font-semibold leading-6 text-green-700">
                                                Learn more <span aria-hidden="true">→</span>
                                            </a>
                                        </p>
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>

            <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8 px-16 lg:px-32 max-h-64 overflow-y-scroll">
                {/*<li key='panorama' className="relative">
                    <div className='group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100'>
                        <div style={{ height: '30vh' }}>
                            <ReactPhotoSphereViewer
                                src={require('../assets/gallery/pano.JPG')}
                                height={'100%'} width={'100%'}
                                littlePlanet={false}
                            ></ReactPhotoSphereViewer>
                        </div>
                    </div>
                    <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">Panorama</p>
                    <p className="pointer-events-none block text-sm font-medium text-gray-500">15 MB</p>
                    </li>*/}
                {files.map((file) => (
                    <li key={file.source} className="relative">
                        <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                            <img src={file.source} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                            <button type="button" className="absolute inset-0 focus:outline-none">
                                <span className="sr-only">View details for {file.title}</span>
                            </button>
                        </div>
                        {/*
                        <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p>
                        <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p>
                */}
                    </li>
                ))}
            </ul>
        </>
    )

};

export default Golf;