import React, { useState } from 'react';
import { XMarkIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

const files = [
    {
        title: 'Review 23',
        source:
            `${require('../assets/reviews/r23.png')}`,
    },
    {
        title: 'Review 22',
        source:
            `${require('../assets/reviews/r22.png')}`,
    },
    {
        title: 'Review 21',
        source:
            `${require('../assets/reviews/r21.png')}`,
    },
    {
        title: 'Review 20',
        source:
            `${require('../assets/reviews/r20.png')}`,
    },
    {
        title: 'Review 19',
        source:
            `${require('../assets/reviews/r19.png')}`,
    },
    {
        title: 'Review 1',
        source:
            `${require('../assets/reviews/r1.png')}`,
    },
    {
        title: 'Review 2',
        source:
            `${require('../assets/reviews/r2.png')}`,
    },
    {
        title: 'Review 3',
        source:
            `${require('../assets/reviews/r3.png')}`,
    },
    {
        title: 'Review 4',
        source:
            `${require('../assets/reviews/r4.png')}`,
    },
    {
        title: 'Review 5',
        source:
            `${require('../assets/reviews/r5.png')}`,
    },
    {
        title: 'Review 6',
        source:
            `${require('../assets/reviews/r6.png')}`,
    },
    {
        title: 'Review 7',
        source:
            `${require('../assets/reviews/r7.png')}`,
    },
    {
        title: 'Review 8',
        source:
            `${require('../assets/reviews/r8.png')}`,
    },
    {
        title: 'Review 9',
        source:
            `${require('../assets/reviews/r9.png')}`,
    },
    {
        title: 'Review 10',
        source:
            `${require('../assets/reviews/r10.png')}`,
    },
    {
        title: 'Review 11',
        source:
            `${require('../assets/reviews/r11.png')}`,
    },
    {
        title: 'Review 12',
        source:
            `${require('../assets/reviews/r12.png')}`,
    },
    {
        title: 'Review 13',
        source:
            `${require('../assets/reviews/r13.png')}`,
    },
    {
        title: 'Review 14',
        source:
            `${require('../assets/reviews/r14.png')}`,
    },
    {
        title: 'Review 15',
        source:
            `${require('../assets/reviews/r15.png')}`,
    },
    {
        title: 'Review 16',
        source:
            `${require('../assets/reviews/r16.png')}`,
    },
    {
        title: 'Review 17',
        source:
            `${require('../assets/reviews/r17.png')}`,
    },
    {
        title: 'Review 18',
        source:
            `${require('../assets/reviews/r18.png')}`,
    },

]

function Reviews() {

    const [selectedImage, setSelectedImage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const openPopover = (index) => {
        setSelectedImage(files[index].source);
        setCurrentIndex(index);
        setIsPopoverOpen(true);
    };

    const closePopover = () => {
        setSelectedImage(null);
        setCurrentIndex(null);
        setIsPopoverOpen(false);
    };

    const nextImage = () => {
        const nextIndex = currentIndex === files.length - 1 ? 0 : currentIndex + 1;
        setSelectedImage(files[nextIndex].source);
        setCurrentIndex(nextIndex);
    };

    const previousImage = () => {
        const previousIndex = currentIndex === 0 ? files.length - 1 : currentIndex - 1;
        setSelectedImage(files[previousIndex].source);
        setCurrentIndex(previousIndex);
    };

    return (
        <>
            <section className="bg-green-50 p-4 mb-8">
                <h2 className="text-2xl font-bold text-center">Client Testimonials</h2>

                <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8 px-16 lg:px-32 max-h-64 overflow-y-scroll">
                    {files.map((file, index) => (
                        <li key={file.source} className="relative">
                            <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 mt-1 mb-1">
                                <img src={file.source} alt={file.title} className="pointer-events-none object-contain group-hover:opacity-75" />


                                <button
                                    type="button"
                                    className="absolute inset-0 focus:outline-none"
                                    onClick={() => openPopover(index)}
                                >
                                    <span className="sr-only">View details for {file.title}</span>
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </section>

            {isPopoverOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-green-50 bg-opacity-50">
                    <div className="relative bg-white p-4 rounded-lg">
                        <XMarkIcon
                            aria-hidden="true"
                            className="absolute top-2 right-2 block h-6 w-6 cursor-pointer"
                            onClick={closePopover}
                        />
                        <ChevronLeftIcon
                            aria-hidden="true"
                            className="absolute left-2 top-1/2 transform -translate-y-1/2 block h-6 w-6 cursor-pointer"
                            onClick={previousImage}
                        />
                        <img
                            onClick={closePopover}
                            src={selectedImage} alt=""
                            className="object-contain max-h-[calc(100vh-4rem)] max-w-[calc(100vw-4rem)] rounded-lg"
                        />
                        <ChevronRightIcon
                            aria-hidden="true"
                            className="absolute right-2 top-1/2 transform -translate-y-1/2 block h-6 w-6 cursor-pointer"
                            onClick={nextImage}
                        />
                    </div>
                </div>
            )}
        </>
    )

};

export default Reviews;