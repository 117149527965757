import React from 'react';
import { ArrowTrendingUpIcon } from '@heroicons/react/24/solid';

function Calltoaction({ formRef }) {

    const scrolltoForm = (e) => {
        // Preventing the default behavior of the form submit (which is to refresh the page)
        e.preventDefault();
        if (formRef && formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <>
            <div className="bg-white flex flex-col items-center justify-center px-4 py-4 sm:px-6 lg:px-8 mb-4">
                <p className="mt-4 font-semibold text-center text-2xl sm:text-xl">
                    Ready to transform your yard with our concrete curbing?
                </p>
                <div className="flex items-center space-x-4 mt-4">
                    <ArrowTrendingUpIcon className="h-12 w-12 text-green-700 transform scale-y-[-1]" />
                    <button
                        onClick={scrolltoForm}
                        className="min-w-[13rem] w-52 rounded-md bg-green-700 px-4 py-2 text-lg font-semibold text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2"
                    >
                        Get a Free Estimate
                    </button>
                </div>
            </div>




        </>
    );
}
export default Calltoaction;