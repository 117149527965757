const items = [
    { id: 1, src: require('../assets/grass/banner100.jpeg'), alt: 'Grass types', ren: '1' },
    { id: 2, src: require('../assets/grass/banner200.jpeg'), alt: 'Grass installation accessories', ren: '1' },
    { id: 3, src: require('../assets/colors/color-chart.jpg'), alt: 'Curber\'s Choice® Premium Integral Colors', ren: '2' },
]

export default function Grass(props) {
    return (
        <ul className="space-y-3">
            {items.map((item) => (
                <div>
                    {(item.ren===props.ren) && <li key={item.id} className="overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6">
                        <img key={item.id} src={item.src} alt={item.alt} />
                    </li>}
                </div>
            ))}
        </ul>
    )
}
